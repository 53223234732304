
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import Select from './select';
import {
    Col,
    Row,
    Container
} from 'reactstrap';
import Text from './text';

class Attributes extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        console.log(this.props.value)
        return (
            <div className="input-wrap" style={{marginTop: 50}}>
                {this.props.label ? <label>{this.props.translate(this.props.label)}</label> : null}

                <Row>
                    {
                        this.props.attributes.map((attribute, aidx) => {
                            return (
                                <Col lg="3" key={attribute.value}>
                                <Text label={attribute.name} translate={this.props.translate} value={this.props.value && this.props.value[attribute.value]} onChange={(val) => {
                                    let newValue = this.props.value;
                                    if (!newValue) newValue = {};

                                    newValue[attribute.value] = val;
                                    this.props.onChange(newValue);
                                    this.forceUpdate()
                                }}></Text>
                            </Col>

                            )
                        })
                    }

                </Row>

            </div>
        );
    }
}

export default Attributes;